/** @jsx jsx */
import { jsx, Flex, Box, Input } from 'theme-ui'
import Layout from '../components/Layout'
import Container from '../components/Container'
import Header from '../components/Header'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import { useEffect, useState } from 'react'
import Card from '../components/Card'
import CardList from '../components/CardList'

const Home = ({ data }) => {
  const [beachStates, setBeachStates] = useState([])
  const [processedStates, setProcessedStates] = useState([])
  const [form, setForm] = useState({
    sort: 'Sort by',
    filter: '',
  })

  useEffect(() => {
    if (data.allContentfulState.nodes) {
      setBeachStates(data.allContentfulState.nodes)
      setProcessedStates(data.allContentfulState.nodes)
    }
  }, [data.allContentfulState.nodes])

  useEffect(() => {
    if (beachStates.length) {
      setProcessedStates([...processStates(beachStates)])
    }
  }, [form])

  const processStates = (beachStates) => {
    let b = beachStates.sort((a, b) => a.name.localeCompare(b.name))

    if (form.sort !== 'Sort by') {
      const sort = form.sort.toLocaleLowerCase()

      const compareValues = (key) => {
        return function innerSort(a, b) {
          // eslint-disable-next-line no-prototype-builtins
          if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0
          }

          const varA =
            typeof a[key] === 'string' ? a[key].toLowerCase() : a[key]
          const varB =
            typeof b[key] === 'string' ? b[key].toLowerCase() : b[key]

          let comparison = a > b
          if (varA > varB) {
            comparison = 1
          } else if (varA < varB) {
            comparison = -1
          }
          return comparison
        }
      }

      b = beachStates.sort(compareValues(sort))
    }

    if (form.filter !== '') {
      const filter = form.filter.toLowerCase()
      b = beachStates.filter((state) =>
        state.name.toLowerCase().includes(filter)
      )
    }

    return b
  }

  return (
    <Layout>
      <SEO
        title="Explore The Beach"
        description="Weather, ocean conditions, ammenities and safety information about all your favorite beaches.  Directly from the lifeguards and safety organizations."
        image={data.contentfulAsset && data.contentfulAsset.file.url}
      />
      <Container>
        <Flex
          sx={{
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Header alert={data.contentfulAlert} />
          <Container mw padded>
            <Flex
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '20px',
                flexDirection: ['column', 'row'],
              }}
            >
              <h1>Where are you?</h1>
              {/* TO DO: Filter/Sort component */}
              <Box
                as="form"
                onSubmit={(e) => {
                  e.preventDefault()
                  setProcessedStates(processStates(beachStates))
                }}
                sx={{ display: 'flex' }}
              >
                <Input
                  sx={{
                    height: '38px',
                    width: '250px',
                    '&::placeholder': {
                      textAlign: 'center',
                    },
                  }}
                  placeholder="Search by State"
                  onChange={(e) => setForm({ ...form, filter: e.target.value })}
                />
              </Box>
            </Flex>
            <Box sx={{ mt: 45 }}>
              <CardList>
                {processedStates.map((state) => {
                  return (
                    <Card
                      key={state.id}
                      imageHeight={'280px'}
                      title={state.name}
                      heroImage={state.image}
                      basePath="state"
                      slug={`${state.slug}`}
                    />
                  )
                })}
              </CardList>
            </Box>
          </Container>
        </Flex>
      </Container>
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomePage {
    contentfulAlert(live: { eq: true }) {
      text
      link
    }
    allContentfulState {
      nodes {
        id
        slug
        name
        image {
          gatsbyImageData(layout: CONSTRAINED, width: 800)
        }
      }
    }
  }
`
